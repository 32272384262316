import { Enum } from '@writercolab/utils';

export { SharedOnboardingRoutes as Routes } from '@web/utils';

export enum Apps {
  AIStudio = '/aistudio',
  WriterApp = '/',
}

export enum InvitedTo {
  AIStudio = 'AiStudio',
  WriterApp = 'WriterApp',
}

export const REQUEST_AUTH_HEADER_NAME = 'x-auth-token';
export const REQUEST_RECAPTCHA_HEADER_NAME = 'x-recaptcha-token';

export const googleSsoUrl = 'auth/social/v2/google';
export const ssoRoute = 'api/sso/session';

export const TSignupPlan = new Enum(
  'team_monthly',
  'free',
  'ai_studio_payg',
  'starter_monthly',
);
export const MFA_RESTART_TIMER = 60; // seconds
export const MFA_RESEND_BACKOFF = 1000 * MFA_RESTART_TIMER; // 1 minute
export const FORGOT_PASSWORD_CODE = 'hash';
