import { Layout } from './components/molecules/Layout/Layout';
import type { LoaderFunctionArgs } from 'react-router';
import { Outlet, replace, useLocation } from 'react-router';
import '../styles/app.css';
import type { ProductName } from '~/@types/types';
import { useEffect, useState } from 'react';
import { Routes } from '~/constants';
import { initDatadog } from './utils/datadog';
import {
  isAuthenticated,
  isCurrentStep,
  isKnownRoute,
  isPrivateRoute,
  isProtectedRoute,
  isRootRoute,
} from './utils/new/auth';
import { hardRedirect } from './utils/routerUtils';
import {
  getItem,
  hasItem,
  LocalStorageKey,
  removeItem,
  setItem,
} from './utils/localStorage';
import { hasSessionItem, setSessionItem } from './utils/sessionStorage';
import { DISCOUNT_PARAM_KEY, SharedOnboardingRoutes } from '@web/utils';
import { baseUrl, fetcher } from './utils/fetcher';
import { getUtmParamsFromRequest } from './utils/utm';
import { identifySegmentUser } from './utils/analytics';
import '../styles/tailwind.css';
import { featureFlags, initFeatureFlags } from './utils/featureFlags';
import { trackPageView } from './utils/analytics-new/utils';
import { getPermissions, getUserDetails } from './utils/network';

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);

  // Caches latest feature flags in localStorage
  await initFeatureFlags();

  if (!hasSessionItem('onboarding:utm')) {
    setSessionItem('onboarding:utm', getUtmParamsFromRequest(request));
  }

  if (!hasItem(LocalStorageKey.OnboardingCompletedQuestionnaire)) {
    setItem(LocalStorageKey.OnboardingCompletedQuestionnaire, false);
  }

  // honor discount query param that we have today in AIS
  if (url.searchParams.has(DISCOUNT_PARAM_KEY)) {
    const discount = url.searchParams.get(DISCOUNT_PARAM_KEY);
    setSessionItem('ais:discount', discount!);
  }

  const step = hasItem(LocalStorageKey.OnboardingStep)
    ? getItem(LocalStorageKey.OnboardingStep)?.split('?')[0]
    : null;

  if (await isAuthenticated()) {
    const isTestVariant =
      featureFlags.get('onboardingV2', 'control') === 'test';

    identifySegmentUser().catch(console.error);

    const discover = await fetcher.GET('/api/user/v2/organization/discover');
    const permissions = await getPermissions();
    const orgId =
      discover.data?.organizationId ||
      Object.keys(permissions?.organizations ?? {})[0];

    if (!hasItem(LocalStorageKey.OnboardingOrgId) && orgId) {
      setItem(LocalStorageKey.OnboardingOrgId, String(orgId));
    }

    const questionnaireCompleted = getItem(
      LocalStorageKey.OnboardingCompletedQuestionnaire,
    );

    if (!questionnaireCompleted) {
      const { data: questionnaire } = await fetcher.GET(
        '/api/user/v3/questionnaire',
      );

      if (questionnaire?.completedAt) {
        setItem(LocalStorageKey.OnboardingCompletedQuestionnaire, true);
      }
    }

    if (step) {
      if (isCurrentStep(url.pathname, step) && isKnownRoute(url.pathname)) {
        return null;
      } else if (!isKnownRoute(step)) {
        removeItem(LocalStorageKey.OnboardingStep);
        return replace(Routes.Login);
      }

      return replace(step);
    } else {
      if (isTestVariant) {
        const { data: questionnaire } = await fetcher.GET(
          '/api/user/v3/questionnaire',
        );

        const questionnaireStep = questionnaire?.data.questionnaireStep;
        const orgId = questionnaire?.organizationId;

        if (orgId) {
          setItem(LocalStorageKey.OnboardingOrgId, String(orgId));
        }

        setSessionItem('onboarding:user-type', 'enterprise');

        if (questionnaireStep) {
          const step = questionnaireStep as SharedOnboardingRoutes;
          setItem(LocalStorageKey.OnboardingStep, step);

          return replace(step);
        } else if (!questionnaire?.completedAt) {
          const user = await getUserDetails();

          if (user?.hasSaml) {
            const discover = await fetcher.GET(
              '/api/user/v2/organization/discover',
            );

            setItem(
              LocalStorageKey.OnboardingOrgId,
              String(discover.data?.organizationId),
            );

            setItem(LocalStorageKey.OnboardingStep, Routes.JoinCreateOrg);

            return replace(Routes.JoinCreateOrg);
          }

          setItem(
            LocalStorageKey.OnboardingStep,
            Routes.OnboardingQuestionIndustry,
          );

          return replace(Routes.OnboardingQuestionIndustry);
        }
      }

      hardRedirect(baseUrl);
      return null;
    }
  } else if (isRootRoute(url.pathname) || isPrivateRoute(url.pathname)) {
    return replace(Routes.Login);
  } else if (isProtectedRoute(url.pathname)) {
    if (step) {
      return !isCurrentStep(url.pathname, step) ? replace(step) : null;
    } else {
      return replace(Routes.Login);
    }
  }

  return null;
};

export default function App() {
  const [product, setProduct] = useState<typeof ProductName.type | undefined>();

  const location = useLocation();

  useEffect(() => {
    initDatadog();
  }, []);

  useEffect(() => {
    if (location.pathname.includes(Routes.OnboardingAIStudio)) {
      setProduct('ai-studio');
    } else if (location.pathname.includes(Routes.OnboardingWriterApp)) {
      setProduct('writer-app');
    } else {
      setProduct(undefined);
    }

    try {
      trackPageView();
    } catch (e) {
      console.error('Failed to track page view', e);
    }
  }, [location]);

  return (
    <Layout product={product}>
      <Outlet />
    </Layout>
  );
}
